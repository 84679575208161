import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import classes from "./HardwareCatalog.module.scss";
import Result from "./Result/Result";
import Sidebar from "./Sidebar/Sidebar";
import AddToComparator from "./AddToComparator/AddToComparator";
import { connect } from "react-redux";
import { SizeMe } from "react-sizeme";
import { Overlay } from "../common/Overlay/Overlay";
import { translate } from "../../translate";

class HardwareCatalog extends Component {
  state = {
    showSidebar: false,
    userHasToggled: false,
    compareIds: []
  };

  render() {
    const { queryResults } = this.props;
    return (
      <React.Fragment>
        <SizeMe>
          {(size) => {
            const width = +size.size.width;
            let sideColumnPosition = "relative";
            if (width <= 790) {
              sideColumnPosition = "absolute";
              if (!this.state.userHasToggled) {
                this.setState({
                  showSidebar: false,
                  userHasToggled: true
                });
              }
            }
            if (width > 790 && !this.state.showSidebar) {
              this.setState({ showSidebar: true, userHasToggled: false });
            }
            return (
              <React.Fragment>
                <Row className={`mx-0`}>
                  <Overlay
                    onClick={() => {
                      if (this.state.showSidebar) {
                        this.setState({ showSidebar: false });
                      }
                    }}
                    show={this.state.showSidebar && width <= 790}
                    zIndex={50}
                  />
                  <Col
                    className={`px-0 ${classes.sidebarColumn}`}
                    xs="10"
                    md="4"
                    style={{
                      position: sideColumnPosition,
                      left: this.state.showSidebar ? "0px" : "-100%"
                    }}
                  >
                    <Sidebar />
                  </Col>
                  <Col className={`p-4 bg-dark`} xs="12" md="8">
                    <div
                      className={`d-flex align-items-center justify-content-between`}
                    >
                      <span className="text-white h5 mb-0">
                        {translate("showingResults")} {queryResults.length}.
                      </span>
                      {width <= 790 ? (
                        <button
                          className={`btn btn-dark`}
                          style={{ zIndex: "101" }}
                        >
                          <FontAwesomeIcon
                            icon={faBars}
                            onClick={() =>
                              this.setState((prevState) => {
                                return {
                                  ...prevState,
                                  showSidebar: !prevState.showSidebar,
                                  userHasToggled: true
                                };
                              })
                            }
                          />
                        </button>
                      ) : null}
                    </div>
                    {queryResults.map((result) => (
                      <Result
                        key={result.id}
                        data={result}
                        comparatorChecked={this.state.compareIds.includes(
                          result.id
                        )}
                        changeCompare={(id, type) => {
                          let appropriateQuery;
                          for (
                            let i = 0;
                            i < this.props.queryResults.length;
                            i++
                          ) {
                            if (this.props.queryResults[i].id === id) {
                              appropriateQuery = this.props.queryResults[i];
                            }
                          }
                          if (type === "Add") {
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                compareIds: [
                                  ...prevState.compareIds,
                                  appropriateQuery.id
                                ]
                              };
                            });
                          } else {
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                compareIds: prevState.compareIds.filter(
                                  (identifier) => identifier !== id
                                )
                              };
                            });
                          }
                        }}
                      />
                    ))}
                    <AddToComparator
                      resultsToCompare={this.props.queryResults.filter(
                        (result) => {
                          return this.state.compareIds.includes(result.id);
                        }
                      )}
                      clearCompareList={() => this.setState({ compareIds: [] })}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            );
          }}
        </SizeMe>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    queryResults: state.results
  };
};

export default connect(mapStateToProps)(HardwareCatalog);
