import React, { useEffect, useState } from "react";
import { translate } from "../../translate";
import { Container, Row, Col, FormGroup, Input, Label } from "reactstrap";
import classes from "./SoftwareCatalog.module.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import Result from "./Result/Result";
import { submitSoftwareQuery } from "../../submitSoftwareQuery";
import {
  getSoftwareActivitySectorLimits,
  getSoftwareSoftwaresLimits,
} from "../../getSoftwareLimits";
import { connect } from "react-redux";
import AddToComparator from "./AddToComparator/AddToComparator";
import { ServiceStrings } from "../../ServiceStrings";

const SoftwareCatalogComponent = (props) => {
  const [selectedActivitySectors, setSelectedActivitySectors] = useState([]);
  const [selectedSoftwareUses, setSelectedSoftwareUses] = useState([]);
  const [findOtherSoftwareText, setFindOtherSoftwareText] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [activitySectors, setActivitySectors] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [compareIds, setCompareIds] = useState([]);

  const history = useHistory();

  // required
  const { translations, updateSoftwareActivitySectors, updateSoftwareUsed } = props;

  const getResults = () => {
    setLoading(true);
    submitSoftwareQuery({
      activitySectors: selectedActivitySectors,
      softwares: selectedSoftwareUses,
      textQuery: findOtherSoftwareText,
    })
      .then((res) => {
        setResults(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (step === 1) {
      setLoading(true);
      getSoftwareActivitySectorLimits()
        .then((result) => {
          setActivitySectors(result.data);
          setLoading(false);
        })
        .catch(console.log);
    } else if (step === 2) {
      setLoading(true);
      getSoftwareSoftwaresLimits(selectedActivitySectors)
        .then((result) => {
          setSoftwares(result.data);
          setLoading(false);
        })
        .catch(console.log);
    } else if (step === 3) {
      getResults();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onActivitySectorStepCompleted = () => {
    updateSoftwareActivitySectors(selectedActivitySectors);
  };

  const onSoftwareUsedStepCompleted = () => {
    updateSoftwareUsed(selectedSoftwareUses);
  };

  const onSelectedActivitySectorChanged = (text, checked) => {
    if (checked) {
      setSelectedActivitySectors(
        selectedActivitySectors.filter((x) => x !== text)
      );
      return;
    }
    setSelectedActivitySectors([...selectedActivitySectors, text]);
  };

  const onSelectedSoftwareChanged = (text, checked) => {
    if (checked) {
      setSelectedSoftwareUses(selectedSoftwareUses.filter((x) => x !== text));
      return;
    }
    setSelectedSoftwareUses([...selectedSoftwareUses, text]);
  };

  const renderStepOneContent = () => {
    return (
      <Col xs="12" className="bg-dark rounded py-3 px-4">
        <span className="text-uppercase">{translate("step1")}</span>
        <h4 className="font-weight-normal">{translate("whatActivity")}</h4>
        <ul className="list-unstyled my-3">
          {activitySectors.length && Array.isArray(activitySectors) ? (
            activitySectors.sort().map((text) => {
              const checked = selectedActivitySectors.includes(text);
              return (
                <li className="my-1" key={text}>
                  <FormGroup key={text} check inline>
                    <Label className={`text-capitalize text-light`} check>
                      <div className="d-inline position-relative">
                        <Input
                          onChange={() =>
                            onSelectedActivitySectorChanged(text, checked)
                          }
                          type="checkbox"
                          checked={checked}
                          name={text}
                        />
                        <span
                          className={`checkmark ${
                            checked ? "bg-primary" : "bg-checkbox-filter"
                          }`}
                        ></span>
                      </div>
                      <span className="ml-1">{translate(text)}</span>
                    </Label>
                  </FormGroup>
                </li>
              );
            })
          ) : (
            <li>{translate("loadFailed")}</li>
          )}
        </ul>
      </Col>
    );
  };

  const renderStepTwoContent = () => {
    return (
      <Col xs="12" className="bg-dark rounded py-3 px-4">
        <span className="text-uppercase">{translate("step2")}</span>
        <h4 className="font-weight-normal">{translate("whatSoftware")}</h4>
        <ul className="list-unstyled my-3">
          {softwares.length &&
            softwares.map((text) => {
              const checked = selectedSoftwareUses.includes(text);
              return (
                <li className="my-1" key={text}>
                  <FormGroup key={text} check inline>
                    <Label className={`text-capitalize text-light`} check>
                      <div className="d-inline position-relative">
                        <Input
                          onChange={() =>
                            onSelectedSoftwareChanged(text, checked)
                          }
                          type="checkbox"
                          checked={checked}
                          name={text}
                        />
                        <span
                          className={`checkmark ${
                            checked ? "bg-primary" : "bg-checkbox-filter"
                          }`}
                        ></span>
                      </div>
                      <span className="ml-1">{translate(text)}</span>
                    </Label>
                  </FormGroup>
                </li>
              );
            })}
        </ul>
        {/* REMOVE "FIND OTHER..." INPUT */}
        {/* <ul
          className={`list-unstyled d-flex align-items-center flex-wrap flex-grow my-2 bg-white pl-3 rounded-pill ${classes.stepTwoTextContainer}`}
        >
          <li className={`my-1 flex-grow ${classes.textQueryContainer}`}>
            <input
              className={`w-100 h-100 border-0 ${classes.textSearchInput}`}
              type="text"
              placeholder={translate("findOtherSoftwareAndRecommendedSolution")}
              value={findOtherSoftwareText}
              onChange={(e) => setFindOtherSoftwareText(e.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === "Enter" && ev.target.value.trim() !== "") {
                  getResults();
                }
              }}
            />
          </li>
          <li>
            <button
              className={`${classes.searchButton} ${
                !findOtherSoftwareText.length ? "disabled" : ""
              }`}
              onClick={getResults}
              disabled={!findOtherSoftwareText.length}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </li>
        </ul> */}
      </Col>
    );
  };

  const renderResults = () => {
    return (
      <Col xs="12" className="text-body px-0">
        {results.length ? (
          <>
            {results.map((res) => {
              return (
                <div key={res.id}>
                  <Result
                    data={res}
                    comparatorChecked={compareIds.includes(res.id)}
                    changeCompare={(id, type) => {
                      let appropriateQuery;
                      for (let i = 0; i < results.length; i++) {
                        if (results[i].id === id) {
                          appropriateQuery = results[i];
                        }
                      }
                      if (type === "Add") {
                        setCompareIds([...compareIds, appropriateQuery.id]);
                      } else {
                        setCompareIds(
                          compareIds.filter((identifier) => identifier !== id)
                        );
                      }
                    }}
                  />
                </div>
              );
            })}
            <AddToComparator
              resultsToCompare={results.filter((result) =>
                compareIds.includes(result.id)
              )}
              clearCompareList={() => setCompareIds([])}
            />
          </>
        ) : (
          <h5 className="text-capitalize text-white">
            {translate("noResults")}
          </h5>
        )}
      </Col>
    );
  };

  let content = null;
  if (step === 1) {
    content = renderStepOneContent();
  } else if (step === 2) {
    content = renderStepTwoContent();
  } else if (step === 3) {
    content = renderResults();
  }

  return (
    <Container
      className={`text-white ${step < 3 && classes.softwareCatalogContainer}`}
    >
      <Row className="my-4">
        <Col xs="12">
          <span
            className={`text-uppercase text-center d-block text-white ${classes.searchByText}`}
          >
            {translate("searchBy")}
          </span>
          <h2 className={`text-uppercase text-white text-center mb-0 very-big`}>
            {translate("softwareFeatures")}
          </h2>
        </Col>
      </Row>
      <Row>{content}</Row>
      <Row className="justify-content-between mb-2 mt-3">
        <button
          className="btn btn-primary border-0 text-capitalize d-flex align-items-center"
          onClick={() => {
            if (step === 1) {
              history.push("/home");
              return;
            }
            setStep(step - 1);
          }}
        >
          <FontAwesomeIcon
            icon={faAngleDoubleLeft}
            className={`mr-2 mb-0 ${classes.backIcon}`}
          />
          {translate("back")}
        </button>
        {step < 3 ? (
          <button
            className="btn btn-primary text-capitalize"
            onClick={() => {
              if (step === 1) {
                onActivitySectorStepCompleted();
              } else if (step === 2) {
                onSoftwareUsedStepCompleted();
              }
              setStep(step + 1);
            }}
            disabled={
              (step === 1 && !selectedActivitySectors.length) ||
              (step === 2 && !selectedSoftwareUses.length)
            }
          >
            {translate("continue")}
          </button>
        ) : null}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    translations: state.translations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSoftwareActivitySectors: (newActivitySectors) => {
      dispatch({ type: ServiceStrings.redux.software.activitySectorsUpdate, payload: newActivitySectors });
    },
    updateSoftwareUsed: (newSoftwareUsed) => {
      dispatch({ type: ServiceStrings.redux.software.softwareUsedUpdate, payload: newSoftwareUsed });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareCatalogComponent);
