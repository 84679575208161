import { ServiceStrings } from "../../ServiceStrings";

const initialState = {
  activitySectors: [],
  softwareUsed: []
};

export const softwareQueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceStrings.redux.software.activitySectorsUpdate:
      return {
        ...state,
        activitySectors: action.payload,
      };
    case ServiceStrings.redux.software.softwareUsedUpdate:
      return {
        ...state,
        softwareUsed: action.payload
      };
    default:
      return { ...state };
  }
};
