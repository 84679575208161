import axios from "axios";

export const getHardwareLimits = () => {
    return axios({
        method: "GET",
        url: "/api/graphicscards/hardwarelimits",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        }
    });
};
