import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import HardwareCatalog from './components/Catalog/HardwareCatalog';
import SoftwareCatalog from './components/Catalog/SoftwareCatalog';
import { ContactUsForm } from './components/ContactUsForm/ContactUsForm';
import { Home } from './components/Home/Home';

import './custom.scss';

export default class App extends Component {
  render () {
    return (
      <Layout>
        <Switch>
          <Route path='/home' component={Home} />
          <Route path='/search-by-hardware' component={HardwareCatalog} />
          <Route path='/search-by-software' component={SoftwareCatalog} />
          <Route path='/contact' component={ContactUsForm} />
          <Redirect to="/home" />
        </Switch>
      </Layout>
    );
  }
}
