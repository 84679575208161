import React, { Component } from "react";
import classes from "./Home.module.scss";
import { Link } from "react-router-dom";
import { translate } from "../../translate";
import { connect } from "react-redux";
class HomeComponent extends Component {
  render() {
    const hardwareNeedsImg = "./images/Hardware_Features.jpg";
    const softwareNeedsImg = "./images/Software_Needs.jpg";

    return (
      <div className={`container`}>
        <div className={`row py-5`}>
          <div className={`col-12`}>
            <div
              className={`d-flex flex-column justify-content-center align-items-center text-white`}
            >
              <span className={`text-uppercase ${classes.smallHeader}`}>
                {translate("pnyPlaceTo")}
              </span>
              <h2
                className={`text-uppercase text-bold very-big ${classes.centerText}`}
              >
                {translate("findGear")}
              </h2>
              <p className={`${classes.centerText}`}>
                {translate("homeDescription")}
              </p>
            </div>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-lg-6 col-12`}>
            <Link to="/search-by-hardware">
              <img
                className={`w-100 rounded ${classes.mb25}`}
                src={hardwareNeedsImg}
                alt="Hardware features logo"
              />
            </Link>
          </div>
          <div className={`col-lg-6 col-12`}>
            <Link to="/search-by-software">
              <img
                className={`w-100 rounded`}
                src={softwareNeedsImg}
                alt="Software needs logo"
              />
            </Link>
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12">
            <div className={`row mx-0 flex-column justify-content-center align-items-center text-white py-5 mt-5 bg-dark rounded ${classes.centerText}`}>
              {/* <span className={`text-uppercase ${classes.smallHeader}`}>
                {translate("download")}
              </span> */}
              <h3 className={`mb-3 ${classes.centerText}`}>
                {translate("nvidiaQuadroSpecifications")}
              </h3>
              <div>
                <a
                  href="https://pnypartners.com/wp-content/uploads/NVIDIA-Server-Linecard.pdf"
                  target="_blank"
                >
                  <button className={`btn btn-primary mr-2`}>
                    {translate("download")}
                  </button>
                </a>
                <Link className={`btn btn-secondary`} to="/contact">
                  {translate("contactUs")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ translations }) => {
  return { translations };
};

export const Home = connect(mapStateToProps)(HomeComponent);
