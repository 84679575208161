import React, { Component } from "react";
import classes from "./AddToComparator.module.scss";
import GraphicsCardImage from "../../../images/quadro-rtx8000-1.jpg";
import { Overlay } from "../../common/Overlay/Overlay";
import { translate } from "../../../translate";

class AddToComparator extends Component {
  state = {
    show: false,
  };

  render() {
    const { resultsToCompare, clearCompareList } = this.props;
    const { show } = this.state;
    const attributesToExclude = [
      "distributorURL",
      "imagePaths",
      "id",
      "memoryUnitOfMeasure",
      "memoryType",
      "activitySectors",
      "softwares",
    ];
    let attributes = ["imagePaths"];
    if (resultsToCompare.length) {
      for (const attr in resultsToCompare[0]) {
        if (!attributesToExclude.includes(attr)) {
          attributes.push(attr);
        }
      }
    }
    let bottom = "0";
    if (show) {
      bottom = "93vh";
    }
    return (
      <React.Fragment>
        <Overlay
          onClick={() => {
            if (show) {
              this.setState({ show: false });
            }
          }}
          show={show}
          zIndex={299}
        />
        {/* z-index of Overlay is 1 below component below */}
        <div
          className={`${classes.comparatorContainer} position-fixed`}
          style={{ bottom }}
        >
          <div
            className={`${classes.tabContainer} position-absolute d-flex align-items-end justify-content-center`}
          >
            <button
              className={`${classes.tab} ${
                resultsToCompare.length < 2 ? "disabled" : null
              } d-flex bg-transparent`}
              onClick={() => {
                if (resultsToCompare.length < 2) {
                  return;
                }
                this.setState((prevState) => ({ show: !prevState.show }));
              }}
            >
              <div className={`${classes.leftHill} bg-primary`}></div>
              <span
                className={`${classes.comparatorText} d-flex align-items-center text-white text-uppercase mb-0 py-2 px-lg-4 px-2 text-nowrap bg-primary small`}
              >{`${translate("productComparison")} (${
                resultsToCompare.length
              })`}</span>
              <div className={`${classes.rightHill} bg-primary`}></div>
            </button>
          </div>
          <div className={`${classes.comfortBar} bg-primary`}></div>
          <div
            className="bg-white p-2 overflow-auto"
            style={{ height: "88vh" }}
          >
            <div className="d-flex align-items-center justify-content-end w-100 mb-2">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  clearCompareList();
                  this.setState({ show: false });
                }}
              >
                {translate("clearComparisons")}
              </button>
            </div>
            <table
              className={`w-100 table table-striped table-responsive d-lg-table`}
            >
              <colgroup>
                <col style={{ width: "fit-content" }} />
                {resultsToCompare.map((result) => {
                  return (
                    <col
                      key={`res-colgroup-key-${result.id}`}
                      style={{
                        width: `${84 / resultsToCompare.length}%`,
                      }}
                    />
                  );
                })}
              </colgroup>
              <thead>
                <tr>
                  <th>{translate("attributes")}</th>
                  {resultsToCompare.map((result) => {
                    return (
                      <th
                        className={`text-nowrap`}
                        key={`main-header-key-${result.id}`}
                        dangerouslySetInnerHTML={{
                          __html: translate(result.displayName),
                        }}
                      />
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {attributes.map((a) => {
                  if (a === "memory") {
                    return (
                      <tr key={`mem-key-${a}`}>
                        <td className={`text-nowrap`}>{translate("memory")}</td>
                        {resultsToCompare.map((result) => {
                          return (
                            <td
                              key={result.id}
                            >{`${result["memory"]}${result["memoryUnitOfMeasure"]} ${result["memoryType"]}`}</td>
                          );
                        })}
                      </tr>
                    );
                  }
                  if (a === "imagePaths") {
                    return (
                      <tr key={`mem-key-${a}`}>
                        <td>{translate("image")}</td>
                        {resultsToCompare.map((result) => {
                          let image = GraphicsCardImage;
                          if (result.imagePaths[0]) {
                            image = `./images/${result.imagePaths[0]}`;
                          }
                          return (
                            <td
                              key={result.id}
                              style={{ verticalAlign: "middle" }}
                            >
                              <img
                                className={`w-50`}
                                style={{ marginLeft: "25%" }}
                                src={image}
                                alt={result.imagePaths[0]}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                  return (
                    <tr key={`name-key-${a}`}>
                      <td className={`text-nowrap`}>{translate(a)}</td>
                      {resultsToCompare.map((res, i) => {
                        const value = res[a];
                        let val = value;
                        if (value === true) {
                          val = translate("yes");
                        } else if (value === false) {
                          val = translate("no");
                        } else if (Array.isArray(value)) {
                          if (value.length) {
                            val = translate(value[0]) || null;
                          } else {
                            val = translate("notApplicable");
                          }
                        }
                        return (
                          <td
                            className={`text-nowrap`}
                            key={i}
                            dangerouslySetInnerHTML={{ __html: val }}
                          />
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AddToComparator;
