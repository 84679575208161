import axios from "axios";

export const getSoftwareActivitySectorLimits = () => {
  return axios({
    method: "GET",
    url: "/api/graphicscards/activities",
    headers: {
        "Content-Type": "application/json; charset=utf-8"
    }
  });
}

export const getSoftwareSoftwaresLimits = (data) => {
  return axios({
    method: "POST",
    url: "/api/graphicscards/softwaresForActivities",
    headers: {
        "Content-Type": "application/json; charset=utf-8"
    },
    data
  });
}
