import { ServiceStrings } from "../../ServiceStrings";

const initialState = {
  isTensorCores: null,
  isVRReady: null,
  isVirtualLink: null,
  isNVLink: null,
  isECC: null,
  isMosaicTechnology: null,
  isPowerConnectorNeeded: null,
  isPassiveCooling: null,
  DistributorKey: null
};

export const queryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceStrings.redux.queryUpdate:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
