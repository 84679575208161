import { ServiceStrings } from "../../ServiceStrings";

const initialState = {};

export const translationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ServiceStrings.redux.translationsUpdate:
            return {...action.payload};
        default:
            return {...state};
    }
};