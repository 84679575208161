import React, { useState } from "react";
import classes from "./Overlay.module.scss";

export const Overlay = (props) => {
  const { show, onClick, zIndex } = props;
  return (
    <div
      className={`${classes.overlay} ${
        show ? "show" : "hide"
      }`}
      style={{ zIndex: zIndex }}
    >
      <button
        className={`${classes.overlayButton}`}
        onClick={onClick}
      ></button>
    </div>
  );
};
