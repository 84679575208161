export const ServiceStrings = {
  redux: {
    queryUpdate: "QUERY UPDATE",
    limitsUpdate: "LIMITS UPDATE",
    resultsUpdate: "RESULTS UPDATE",
    translationsUpdate: "TRANSLATIONS UPDATE",
    software: {
      activitySectorsUpdate: "SOFTWARE ACTIVITY SECTORS UPDATE",
      softwareUsedUpdate: "SOFTWARE SOFTWARE USED UPDATE"
    }
  }
}