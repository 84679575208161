import React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
// import { Collapse, Container, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import classes from './CatalogNavMenu.module.scss';
import SearchFieldBubbles from '../../SearchFieldBubbles/SearchFieldBubbles';
import { connect } from 'react-redux';
import { translate } from '../../../translate';
import { ServiceStrings } from '../../../ServiceStrings';

export class CatNavMenu extends Component {
  static displayName = CatNavMenu.name;

  state = {
    collapsed: true,
    textQuery: ""
  };

  toggleNavbar = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        collapsed: !prevState.collapsed
      }
    });
  }

  sendNewQuery = () => {
    if (this.state.textQuery.length) {
      this.props.updateQuery({ textQuery: this.state.textQuery });
    }
    this.setState({ textQuery: "" });
  }

  render() {
    const blackLogoImg = "./images/logo-black.png" || null;
    return (
      <header>
        <Navbar className="navbar-expand-md navbar-toggleable-md bg-light border-bottom box-shadow p-3 zi-200" light>
          <Link to="/home">
            <img alt="Logo white" src={blackLogoImg} height="75" />
          </Link>
          <div className="d-flex flex-column flex-grow col-12 col-md-auto">
            <NavbarBrand tag={Link} to="/search-by-hardware" className="d-flex flex-column py-0 mb-2">
              <span style={{ fontSize: '1rem', marginBottom: '-6px', fontSize: "60%" }} className={`text-uppercase`}>{translate("searchBy")}</span>
              <h2 style={{ fontSize: '1.25rem' }} className={`text-uppercase mb-0`}>{translate("hardwareFeatures")}</h2>
            </NavbarBrand>
            <div className={`position-relative bg-white d-flex align-items-center mt-2 mb-1 my-md-0 w-100 h-100 p-1 rounded ${classes.searchFieldBubblesWrapper}`}>
              <SearchFieldBubbles textQueryValue={this.state.textQuery} 
              updateTextQuery={(text) => {
                this.setState({ textQuery: text });
              }} 
              sendNewQuery={this.sendNewQuery}
              />
              <button className={`btn px-0 py-0 ${classes.searchIconBtn}`}
                onClick={() => {
                  this.sendNewQuery();
                }}>
                <FontAwesomeIcon icon={faSearch} className={`${classes.searchIcon} bg-dark text-light`} />
              </button>
            </div>
          </div>
          <div className="d-flex flex-md-column align-self-stretch justify-content-between ml-3 mb-md-2">
            <Link to="/contact" className="btn btn-outline-primary mt-2 mt-md-0">
              <span className="text-nowrap">{translate("contactUs")}</span>
            </Link>
            <LanguageSelector />
          </div>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = ({ translations, queries }) => {
  return { translations, queries };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuery: (query) => {
      dispatch({ type: ServiceStrings.redux.queryUpdate, payload: query })
    }
  }
}

export const CatalogNavMenu = connect(mapStateToProps, mapDispatchToProps)(CatNavMenu);