import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "reactstrap";
import classes from "./Footer.module.scss";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { translate } from "../../translate";
import { connect } from "react-redux";

// const logoXTwitter = "./images/twitter-pro.svg";

const FooterComponent = (props) => {
  // required
  const { translations } = props;
  const socialMediaIcons = [
    {
      path: "https://bit.ly/3cvGiX5",
      icon: faXTwitter,
    },
    {
      path: "https://bit.ly/2WTmdmP",
      icon: faFacebook,
    },
    {
      path: "https://bit.ly/2WPYPXy",
      icon: faYoutube,
    },
    {
      path: "https://bit.ly/2Z0refW",
      icon: faLinkedin,
    },
    {
      path: "https://bit.ly/2T1plMd",
      icon: faInstagram,
    },
  ];

  const NVIDIABlackLogo = "./images/PNY-NVIDIA-Lockup-Dark-Background.png";

  return (
    <footer>
      <Container className={`${classes.footerContainer}`}>
        <Row>
          <Col xs="12" sm="8">
            <span className="text-muted">{translate("copyrightMessage")}</span>
          </Col>
          <Col xs="12" sm="4">
            <div className="justify-content-sm-end ml-0 my-4 my-sm-0 d-flex">
              <div>
                <h6 className="text-muted">Connect</h6>
                {socialMediaIcons.map((iconData) => {
                  const { path, icon } = iconData;
                  return (
                    <a
                      href={path}
                      key={path}
                      className="text-muted hover-primary pr-2 py-2"
                      target="_blank"
                      rel="noreferrer nobearer"
                    >
                      <FontAwesomeIcon icon={icon} />
                    </a>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="py-3 pt-4">
          <Col xs="12" className="mb-2">
            <a
              className="text-muted hover-primary"
              href="https://www.pny.com/terms-of-use"
              target="_blank"
              rel="noreferrer nobearer"
            >
              {translate("termsOfUse")}
            </a>
            &nbsp; &middot; &nbsp;
            <a
              className="text-muted hover-primary"
              href="https://www.pny.com/legal-footer/privacy-policy"
              target="_blank"
              rel="noreferrer nobearer"
            >
              {translate("privacyPolicy")}
            </a>
          </Col>
          <Col xs="12">
            <img src={NVIDIABlackLogo} height="80" />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(FooterComponent);
