import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import {
  queryReducer,
  resultsReducer,
  translationsReducer,
  limitsReducer,
  softwareQueryReducer
} from './store/reducers';
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import createSagaMiddleware from 'redux-saga';
import { querySaga } from './store/sagas/querySaga';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const rootReducer = combineReducers({
    queries: queryReducer,
    results: resultsReducer,
    translations: translationsReducer,
    limits: limitsReducer,
    softwareQuery: softwareQueryReducer
});

const sagaMiddleWare = createSagaMiddleware();
//const composedEnhancer = compose(applyMiddleware(sagaMiddleWare), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));
sagaMiddleWare.run(querySaga);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </Provider>,
    rootElement
);

registerServiceWorker();