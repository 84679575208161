import { ServiceStrings } from "../../ServiceStrings";

const initialState = {};

export const limitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceStrings.redux.limitsUpdate:
      return { ...action.payload };
    default:
      return { ...state };
  }
};
