import axios from "axios";

export const submitHardwareQuery = (query) => {
    return axios({
        method: "POST",
        url: "/api/graphicscards/searchbyhardware",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        data: query
    });
};
