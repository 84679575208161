import React, { Component } from 'react';
import { SizeMe } from 'react-sizeme';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './FilterAccordionWrapper.module.scss';

//This component accepts either props for a boolean filter or children which it will wrap around and provide collapse functionality
class FilterAccordionWrapper extends Component {
  state = {
    show: true,
    optionsDivHeight: null,
  }

  render() {
    return (
      <div className={``}>
        <div className={`mb-2`}>
          <button onClick={ev => {
            ev.preventDefault();
            this.setState(prevState => {
              return {
                ...prevState,
                show: !prevState.show,
              }
            });
          }} className={`btn d-flex justify-content-between align-items-center w-100 text-left px-3 h5 mb-0 ${this.state.show ? 'text-light' : 'text-muted'}`}>
            {this.props.filterTitle}
            <FontAwesomeIcon icon={this.state.show ? faChevronCircleUp : faChevronCircleDown}/>
          </button>
        </div>
        <SizeMe monitorHeight>
          {({ size }) => {
            if (!this.state.optionsDivHeight && size.height) {
              this.setState({
                optionsDivHeight: size.height + 'px',
              })
            }
            return <div style={{ maxHeight: this.state.optionsDivHeight }}
              className={`d-flex flex-column pl-3 overflow-hidden ${classes.collapseContainer} ${this.state.show ? '' : classes.collapsed}`}>
              {this.props.children}
            </div>
          }}
        </SizeMe>
      </div>
    );
  }
}

export default FilterAccordionWrapper;