import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ServiceStrings } from "../../ServiceStrings";
// import classes from "./LanguageSelector.module.scss";

const LanguageSelector = (props) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ?? "en"
  );

  const { classes } = props;

  useEffect(() => {
    axios
      .get(`/api/translation/dictionary?lang=${language}`)
      .then((res) => props.updateTranslations(res.data))
      .catch(console.log);
  }, [language]);

  return (
    <select
      className={
        classes ??
        "bg-dark form-control ml-2 ml-md-0 mt-2 mt-md-0 pointer text-white w-fit-content"
      }
      value={language}
      onChange={(event) => {
        localStorage.setItem("language", event.target.value);
        setLanguage(event.target.value);
      }}
    >
      <option value="en">English</option>
      <option value="es">Español</option>
      <option value="pt">Português</option>
      <option value="de">Deutsch</option>
      <option value="fr">Français</option>
    </select>
  );
};

const mapStateToProps = ({ translations }) => {
  return { translations };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTranslations: (translationsData) =>
      dispatch({ type: ServiceStrings.redux.translationsUpdate, payload: translationsData })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
