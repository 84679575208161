import React from 'react';
import { StandardNavMenu, CatalogNavMenu } from './NavMenus';
import { useLocation } from 'react-router-dom';
import Footer from './Footer/Footer';

export const Layout = (props) => {
  const location = useLocation();
  const path = location.pathname;
  let navMenu = <StandardNavMenu />;
  if (path === '/search-by-hardware') {
    navMenu = <CatalogNavMenu />;
  }
  return (
    <div className={`position-relative`}>
      {navMenu}
      {props.children}
      <Footer />
    </div>
  );
}
