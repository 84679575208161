import React, { useState } from "react";
import GraphicsCardImage from "../../../images/quadro-rtx8000-1.jpg";
import { FormGroup, Input, Label } from "reactstrap";
import { translate } from "../../../translate";
import classes from "./Result.module.scss";

const Result = ({ data, changeCompare, comparatorChecked }) => {
  const {
    id,
    displayName,
    cudaCores,
    memory,
    numberOfSlots,
    maxPowerConsumption,
    isPowerConnectorNeeded,
    isPassiveCooling,
    displayConnectors,
    maximumActiveDisplays,
    isMosaicTechnology,
    memoryBandwidth,
    memoryInterface,
    isECC,
    isTensorCores,
    isVRReady,
    isVirtualLink,
    isNVLink,
    gpuArchitecture,
  } = data;

  const rowData = [
    { title: "cudaCores", value: cudaCores },
    { title: "memory", value: memory },
    { title: "numberOfSlots", value: numberOfSlots },
    { title: "maxPowerConsumption", value: maxPowerConsumption },
    { title: "isPowerConnectorNeeded", value: isPowerConnectorNeeded },
    { title: "isPassiveCooling", value: isPassiveCooling },
    { title: "displayConnectors", value: displayConnectors },
    { title: "maximumActiveDisplays", value: maximumActiveDisplays },
    { title: "isMosaicTechnology", value: isMosaicTechnology },
    { title: "memoryBandwidth", value: memoryBandwidth },
    { title: "memoryInterface", value: memoryInterface },
    { title: "isECC", value: isECC },
    { title: "gpuArchitecture", value: gpuArchitecture },
    { title: "isTensorCores", value: isTensorCores },
    { title: "isVRReady", value: isVRReady },
    { title: "isVirtualLink", value: isVirtualLink },
    { title: "isNVLink", value: isNVLink },
  ];

  let image = GraphicsCardImage;
  if (data.imagePaths[0]) {
    image = `./images/${data.imagePaths[0]}`;
  }

  return (
    <section className={`mt-3 rounded bg-white overflow-hidden`}>
      <div
        className={`d-flex flex-column flex-xl-row justify-content-between align-items-stretch`}
      >
        <div
          className={`${classes.cardVisualsContainer} px-0 d-flex flex-column justify-content-end bg-light`}
        >
          <img
            className={`w-50 align-self-center`}
            src={image}
            alt="NVIDIA GPU Image"
          />
          {/* <h4 className={`h2 my-2 mx-4 text-center`}>{displayName}</h4> */}
          <h4 className={`h2 my-2 mx-4 text-center`} dangerouslySetInnerHTML={{ __html: displayName }} />
          <div
            className={`d-flex align-items-center ${classes.leaveContainer}`}
          >
            <a
              href="/contact"
              target="_blank"
              rel="noreferrer noopener"
              className={`btn btn-primary my-3 font-weight-bold`}
            >
              {translate("buyNow")}
            </a>
            <a
              href={data.productPage}
              target="_blank"
              rel="noreferrer noopener"
              className={`btn btn-outline-primary my-3 font-weight-bold ${classes.learnMore}`}
            >
              {translate("learnMore")}
            </a>
          </div>
          <FormGroup
            check
            inline
            className={`d-flex justify-content-center align-items-end p-2 bg-grey mr-0`}
          >
            <Label
              className={`mb-0 position-relative`}
              checked={comparatorChecked}
            >
              <Input
                onChange={() => {
                  changeCompare(id, comparatorChecked ? "Remove" : "Add");
                }}
                type="checkbox"
                className={`${classes.checkmarkInput} mb-1 mr-3`}
                checked={comparatorChecked}
                name={"Add To Comparator"}
              />
              <span
                className={`checkmark ${
                  comparatorChecked ? "bg-primary" : "bg-checkbox-comparator"
                }`}
              ></span>
              {translate("addToCompare")}
            </Label>
          </FormGroup>
        </div>
        <div className={`${classes.dataPoints} p-4 py-5`}>
          {rowData.slice(0, rowData.length - 1).map((data, index) => {
            const { value, title } = data;
            let val = value;
            if (value === true) {
              val = translate("yes");
            } else if (value === false) {
              val = translate("no");
            }
            return (
              <div
                key={data.title}
                className={`${
                  index % 2 === 0 && "bg-light"
                } d-flex justify-content-between`}
                style={{ padding: "0.75rem" }}
              >
                <span className={`text-capitalize`}>{translate(title)}</span>
                <span className={`font-weight-bold`}>{val}</span>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Result;
