import React, { useEffect, useState } from "react";
import classes from "./ContactUsForm.module.scss";
import { translate } from "../../translate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

const USStates = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

const CanadianProvinces = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon Territory"
];

const ContactForm = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [receiveMarketingEmails, setReceiveMarketingEmails] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => {
        if (res.status === 200 && res.data) {
          const countriesSorted = res.data.sort((a, b) => {
            return a.name.common > b.name.common ? 1 : b.name.common > a.name.common ? -1 : 0;
          });
          setCountries(countriesSorted);
          setValue("countryContactForm", countriesSorted[0].name.common);
          setSelectedCountry(countriesSorted[0].name.common);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const code = {
    firstNameContactForm: "firstName",
    lastNameContactForm: "lastName",
    companyContactForm: "company",
    emailContactForm: "email",
    countryContactForm: "country",
    stateContactForm: "state",
    postalCodeContactForm: "postalCode",
    hardwareQueryData: props.hardwareQueries,
    softwareQueryData: props.softwareQueries
  };

  const countrySelect = register("countryContactForm", {
    required: {
      value: true,
      message: translate("requiredField")
    }
  });

  const submitContactForm = (data) => {
    const properFieldData = {
      isOptedIn: receiveMarketingEmails
    };
    for (const fieldName in data) {
      properFieldData[code[fieldName]] = data[fieldName];
    }

    axios({
      method: "POST",
      url: "/api/contactus/submit",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: {
        hardwareQueryData: props.hardwareQueries,
        softwareQueryData: props.softwareQueries,
        ...properFieldData
      }
    })
      .then((res) => {
        setShowConfirmation(true);
        console.log(res);
      })
      .catch((err) => {
        alert(err.response.data);
        console.log(err);
      });
  };

  if (showConfirmation) {
    return (
      <div className="container p-5">
        <div className="col-12 border bg-light p-4 rounded">
          <h4>{translate("contactRequestConfirmationMessage")}</h4>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <form
        className={`container pt-5`}
        onSubmit={handleSubmit(submitContactForm)}>
        <div className={`col-12 border bg-light p-4 rounded`}>
          <div className={`row flex-column mb-3`}>
            <div className={`col-12`}>
              <h2 className={`mb-0`}>{translate("contactUs")}</h2>
              <span className={`text-muted`}>{translate("hearFromYou")}</span>
            </div>
          </div>
          <div className={`row mb-4`}>
            <div className={`col-md-6`}>
              <label htmlFor="firstNameContactForm" className="control-label">
                <span className="text-capitalize">
                  {translate("firstName")}
                </span>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <div className="input-group">
                <input
                  className={`w-100 ${classes.formInput}`}
                  placeholder={translate("firstName")}
                  id="firstNameContactForm"
                  {...register("firstNameContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    }
                  })}
                />
                <div className="input-group-append w-100 pt-1 pl-1">
                  {errors.firstNameContactForm ? (
                    <span className="text-danger">
                      {errors.firstNameContactForm.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`col-md-6`}>
              <label htmlFor="lastNameContactForm" className="control-label">
                <span className="text-capitalize">{translate("lastName")}</span>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <div className="input-group">
                <input
                  className={`w-100 ${classes.formInput}`}
                  id="lastNameContactForm"
                  placeholder={translate("lastName")}
                  {...register("lastNameContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    }
                  })}
                />
                <div className="input-group-append w-100 pt-1 pl-1">
                  {errors.lastNameContactForm ? (
                    <span className="text-danger">
                      {errors.lastNameContactForm.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={`row mb-4`}>
            <div className={`col-md-6`}>
              <label htmlFor="companyContactForm" className="control-label">
                <span className="text-capitalize">{translate("company")}</span>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <div className="input-group">
                <input
                  className={`w-100 ${classes.formInput}`}
                  id="companyContactForm"
                  placeholder={translate("company")}
                  {...register("companyContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    }
                  })}
                />
                <div className="input-group-append w-100 pt-1 pl-1">
                  {errors.companyContactForm ? (
                    <span className="text-danger">
                      {errors.companyContactForm.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`col-md-6`}>
              <label htmlFor="emailContactForm" className="control-label">
                <span className="text-capitalize">{translate("email")}</span>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <div className="input-group">
                <input
                  className={`w-100 ${classes.formInput}`}
                  id="emailContactForm"
                  placeholder={translate("email")}
                  {...register("emailContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    },
                    pattern: {
                      value:
                        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: translate("email_format_invalid")
                    }
                  })}
                />
                <div className="input-group-append w-100 pt-1 pl-1">
                  {errors.emailContactForm ? (
                    <span className="text-danger">
                      {errors.emailContactForm.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={`row mb-4`}>
            <div className={`col-md-6`}>
              <label htmlFor="countryContactForm" className="control-label">
                <span className="text-capitalize">{translate("country")}</span>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <div className="input-group">
                {countries ? (
                  <select
                    className="w-100"
                    style={{ paddingTop: "0.23rem", paddingBottom: "0.23rem" }}
                    placeholder={translate("country")}
                    id="countryContactForm"
                    {...countrySelect}
                    onChange={(e) => {
                      countrySelect.onChange(e);
                      setSelectedCountry(e.target.value);
                    }}
                  >
                    {countries.map((ctry) => {
                      return (
                        <option key={ctry.name.common} value={ctry.name.common}>
                          {ctry.name.common}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <input
                    placeholder={translate("country")}
                    className="w-100"
                    id="countryContactForm"
                    {...register("countryContactForm", {
                      required: {
                        value: true,
                        message: translate("requiredField")
                      }
                    })}
                  />
                )}
                <div className="input-group-append w-100 pt-1 pl-1">
                  {errors.countryContactForm ? (
                    <span className="text-danger">
                      {errors.countryContactForm.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`col-md-6`}>
              <label htmlFor="stateContactForm" className="control-label">
              <span className="text-capitalize">
                  State/Province
              </span>
                {/* <span className="text-capitalize">
                  {translate(
                    selectedCountry === "Canada" ? "province" : "state"
                  )}
                </span> */}
                <span className="text-danger">&nbsp;*</span>
              </label>
              {selectedCountry === "United States of America" ||
              selectedCountry === "Canada" ? (
                <select
                  className="w-100 text-capitalize"
                  style={{ paddingTop: "0.23rem", paddingBottom: "0.23rem" }}
                  id="stateContactForm"
                  {...register("stateContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    },
                    validate: {
                      isNotSelect: (value) => {
                        if (value === "Select") {
                          return translate("requiredField");
                        } else {
                          return true;
                        }
                      }
                    }
                  })}
                >
                  <option
                    className="text-capitalize"
                    value={translate("select")}
                  >
                    {translate("select")}
                  </option>
                  {selectedCountry === "Canada"
                    ? CanadianProvinces.map((provinceName) => {
                        return (
                          <option
                            key={provinceName}
                            className="text-capitalize"
                            value={provinceName}
                          >
                            {provinceName}
                          </option>
                        );
                      })
                    : USStates.map((stateName) => {
                        return (
                          <option
                            key={stateName}
                            className="text-capitalize"
                            value={stateName}
                          >
                            {stateName}
                          </option>
                        );
                      })}
                </select>
              ) : (
                <input
                  className="w-100 text-capitalize"
                  id="stateContactForm"
                  {...register("stateContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    }
                  })}
                />
              )}
              <div className="input-group-append w-100 pt-1 pl-1">
                {errors.stateContactForm ? (
                  <span className="text-danger">
                    {errors.stateContactForm.message}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-6`}>
              <label htmlFor="postalCodeContactForm" className="control-label">
                <span className="text-capitalize">{translate("zipCode")}</span>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <div className="input-group">
                <input
                  placeholder={translate("zipCode")}
                  className={`w-100`}
                  id="postalCodeContactForm"
                  {...register("postalCodeContactForm", {
                    required: {
                      value: true,
                      message: translate("requiredField")
                    },
                    // pattern: {
                    //   value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                    //   message: translate("formatInvalid")
                    // }
                  })}
                />
                <div className="input-group-append w-100 pt-1 pl-1">
                  {errors.postalCodeContactForm ? (
                    <span className="text-danger">
                      {errors.postalCodeContactForm.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`col-6`}>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <h4>{translate("optIn")}</h4>
                <p>{translate("contactPrivacyStatement")}</p>
                <div className="form-check form-check-inline mb-2 ml-3">
                  <label className={`text-capitalize`}>
                    <input
                      onChange={(e) => {
                        return setReceiveMarketingEmails(
                          !receiveMarketingEmails
                        );
                      }}
                      value={receiveMarketingEmails}
                      type="checkbox"
                      className={`mr-2`}
                    />
                    <span
                      className={`checkmark mt-n1 ${
                        receiveMarketingEmails
                          ? "bg-primary"
                          : "bg-checkbox-filter"
                      }`}
                    ></span>
                    {translate("sendMeEmails")}
                  </label>
                </div>
                <p>{translate("youCanUnsubscribe")}</p>
                <p>{translate("infoStorageConsent")}</p>
                <div className="d-flex align-items-center justify-content-end w-100">
                  <input
                    className={`btn btn-success px-4 mr-3`}
                    value={translate("submit")}
                    type="submit"
                  />
                  <Link to="/" className={`btn btn-danger px-4`}>
                    {translate("cancel")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    hardwareQueries: state.queries,
    softwareQueries: {
        activitysectors: state.softwareQuery.activitySectors,
        softwares: state.softwareQuery.softwareUsed
    }
  };
};

export const ContactUsForm = connect(mapStateToProps)(ContactForm);
