import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate } from "../../../translate";
import { Navbar, NavbarBrand } from "reactstrap";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";

class StandardNavMenuComponent extends Component {
  render() {
    const whiteLogo = "./images/logo-white.png" || null;
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm bg-dark border-bottom box-shadow py-0 justify-content-between"
          dark
        >
          <div className={`d-flex align-items-center`}>
            <NavbarBrand tag={Link} to="/home">
              <img src={whiteLogo} alt="PNY Logo" height="50" />
            </NavbarBrand>
            <a
              className={`text-white ml-4 h6 mb-0`}
              href="https://www.pny.com/professional"
              target="_blank"
              rel="noreferrer noopener"
            >
              {translate("learnMoreAboutUs")}
              &nbsp;
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </a>
          </div>
          <div className={`d-flex align-items-center`}>
            <a
              className={`text-white p-2 h6 mb-0`}
              href="https://www.pny.com/company/press-center/newsletters"
              target="_black"
              rel="nobearer noreferrer"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
              &nbsp; PNY Pro Newsletter
            </a>
            <div className="border-left h-100 mx-3"></div>
            <Link to="/contact" className="btn btn-outline-primary mt-2 mt-md-0 mr-4">
              <span className="text-nowrap">{translate("contactUs")}</span>
            </Link>
            <LanguageSelector classes="text-primary border-0 bg-dark p-1" />
          </div>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = ({ translations }) => {
  return { translations };
}

export const StandardNavMenu = connect(mapStateToProps)(StandardNavMenuComponent);
