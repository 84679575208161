import React, { Component } from "react";
import { Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap";
import { translate } from "../../../translate";
import "rc-slider/assets/index.css";
import CheckboxFilterAccordion from "../../common/FilterAccordion/CheckboxFilterAccordion/CheckboxFilterAccordion";
import SliderFilterAccordion from "../../common/FilterAccordion/SliderFilterAccordion/SliderFilterAccordion";
import FilterAccordionWrapper from "../../common/FilterAccordion/FilterAccordionWrapper/FilterAccordionWrapper";
import { getHardwareLimits } from "../../../getHardwareLimits";
import { connect } from "react-redux";
import { ServiceStrings } from "../../../ServiceStrings";

class Sidebar extends Component {
  state = {
    gpuArchitectures: [],
    memoryTypes: [],
  };

  componentDidMount() {
    getHardwareLimits()
      .then((res) => {
        const { gpuArchitectures, memoryTypes, ...remaining } = res.data;
        const { updateQuery, updateLimits } = this.props;
        this.setState({
          gpuArchitectures: gpuArchitectures,
          memoryTypes: memoryTypes,
        });
        const limitsAndBooleanQueriesCombined = {
          ...this.props.queries,
          gpuArchitectures: [],
          ...remaining,
        };
        updateQuery(limitsAndBooleanQueriesCombined);
        updateLimits(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  clearAllFilters = (e) => {
    const { gpuArchitectures, memoryTypes, ...remaining } = this.props.limits;
    this.props.updateQuery({
      ...remaining,
      isTensorCores: null,
      isVRReady: null,
      isVirtualLink: null,
      isNVLink: null,
      isECC: null,
      isPowerConnectorNeeded: null,
      isPassiveCooling: null,
      isMosaicTechnology: null,
      gpuArchitectures: [],
    });
  };

  render() {
    const {
      memoryMin,
      memoryMax,
      cudaCoresMin,
      cudaCoresMax,
      isTensorCores,
      isVRReady,
      isVirtualLink,
      isNVLink,
      isECC,
      memoryBandwidthMin,
      memoryBandwidthMax,
      memoryInterfaceMin,
      memoryInterfaceMax,
      maximumActiveDisplaysMin,
      maximumActiveDisplaysMax,
      displayConnectorsMax,
      displayConnectorsMin,
      isMosaicTechnology,
      maxPowerConsumptionMin,
      maxPowerConsumptionMax,
      isPowerConnectorNeeded,
      isPassiveCooling,
      numberOfSlotsMin,
      numberOfSlotsMax,
    } = this.props.queries;

    const { updateQuery, limits } = this.props;

    const { gpuArchitectures } = this.state;

    return (
      <Form>
        <Card className={`border-0`}>
          <CardBody className="text-center bg-darker p-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span
                className={`text-uppercase text-left text-light font-weight-light pl-3 d-block`}
              >
                {translate("filterResults")}
              </span>
              <button
                type="button"
                className="btn btn-outline-light px-2 py-1"
                onClick={this.clearAllFilters}
              >
                {translate("clearAllFilters")}
              </button>
            </div>
            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <FilterAccordionWrapper
                filterTitle={translate("gpuArchitecture")}
              >
                {gpuArchitectures.sort().map((name) => {
                  const checked =
                    this.props.queries.gpuArchitectures?.includes(name) ||
                    false;
                  return (
                    <FormGroup key={name} check inline>
                      <Label className={`text-capitalize text-light`} check>
                        <Input
                          onChange={() => {
                            updateQuery({
                              gpuArchitectures:
                                this.props.queries.gpuArchitectures.indexOf(
                                  name
                                ) >= 0
                                  ? this.props.queries.gpuArchitectures.filter(
                                      (n) => n != name
                                    )
                                  : [
                                      ...this.props.queries.gpuArchitectures,
                                      name,
                                    ],
                            });
                          }}
                          type="checkbox"
                          className={`mr-2`}
                          checked={checked}
                          name={name}
                        />
                        <span
                          className={`checkmark ${
                            checked ? "bg-primary" : "bg-checkbox-filter"
                          }`}
                        ></span>
                        {translate(name)}
                      </Label>
                    </FormGroup>
                  );
                })}
              </FilterAccordionWrapper>
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("memory")}
                rangeName="GPUMemoryGB"
                min={limits.memoryMin}
                max={limits.memoryMax}
                curValues={[memoryMin, memoryMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    memoryMin: min,
                    memoryMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("cudaCores")}
                rangeName="CUDACores"
                min={limits.cudaCoresMin}
                max={limits.cudaCoresMax}
                curValues={[cudaCoresMin, cudaCoresMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    cudaCoresMin: min,
                    cudaCoresMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isTensorCores")}
                filterStateName="isTensorCores"
                currentlySelected={isTensorCores}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isVRReady")}
                filterStateName="isVRReady"
                currentlySelected={isVRReady}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isVirtualLink")}
                filterStateName="isVirtualLink"
                currentlySelected={isVirtualLink}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isNVLink")}
                filterStateName="isNVLink"
                currentlySelected={isNVLink}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isECC")}
                filterStateName="isECC"
                currentlySelected={isECC}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isMosaicTechnology")}
                filterStateName="isMosaicTechnology"
                currentlySelected={isMosaicTechnology}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isPowerConnectorNeeded")}
                filterStateName="isPowerConnectorNeeded"
                currentlySelected={isPowerConnectorNeeded}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-left`}
            >
              <CheckboxFilterAccordion
                filterTitle={translate("isPassiveCooling")}
                filterStateName="isPassiveCooling"
                currentlySelected={isPassiveCooling}
                options={[
                  {
                    label: translate("yes"),
                    callbackValue: true,
                  },
                  // {
                  //   label: translate("no"),
                  //   callbackValue: false,
                  // },
                ]}
                optHandler={updateQuery}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("memoryBandwidth")}
                rangeName="MemoryBandwidth"
                min={limits.memoryBandwidthMin}
                max={limits.memoryBandwidthMax}
                curValues={[memoryBandwidthMin, memoryBandwidthMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    memoryBandwidthMin: min,
                    memoryBandwidthMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />
            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("memoryInterface")}
                rangeName="MemoryInterface"
                min={limits.memoryInterfaceMin}
                max={limits.memoryInterfaceMax}
                curValues={[memoryInterfaceMin, memoryInterfaceMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    memoryInterfaceMin: min,
                    memoryInterfaceMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("maximumActiveDisplays")}
                rangeName="MaximumActiveDisplays"
                min={limits.maximumActiveDisplaysMin}
                max={limits.maximumActiveDisplaysMax}
                curValues={[maximumActiveDisplaysMin, maximumActiveDisplaysMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    maximumActiveDisplaysMin: min,
                    maximumActiveDisplaysMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("displayConnectors")}
                rangeName="DisplayConnectors"
                min={limits.displayConnectorsMin}
                max={limits.displayConnectorsMax}
                curValues={[displayConnectorsMin, displayConnectorsMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    displayConnectorsMin: min,
                    displayConnectorsMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup>
              <SliderFilterAccordion
                filterTitle={translate("maxPowerConsumption")}
                rangeName="MaxPowerConsumption"
                min={limits.maxPowerConsumptionMin}
                max={limits.maxPowerConsumptionMax}
                curValues={[maxPowerConsumptionMin, maxPowerConsumptionMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    maxPowerConsumptionMin: min,
                    maxPowerConsumptionMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />

            <FormGroup
              className={`d-flex flex-column justify-content-start text-center`}
            >
              <SliderFilterAccordion
                filterTitle={translate("numberOfSlots")}
                rangeName="NumberOfSlots"
                min={limits.numberOfSlotsMin}
                max={limits.numberOfSlotsMax}
                curValues={[numberOfSlotsMin, numberOfSlotsMax]}
                onAfterChange={(min, max) => {
                  updateQuery({
                    numberOfSlotsMin: min,
                    numberOfSlotsMax: max,
                  });
                }}
              />
            </FormGroup>
            <hr className={`m-0`} />
          </CardBody>
        </Card>
      </Form>
    );
  }
}

const mapStateToProps = ({ queries, limits }) => {
  return { queries, limits };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuery: (changedQueries) => {
      dispatch({ type: ServiceStrings.redux.queryUpdate, payload: changedQueries });
    },
    updateLimits: (limits) => {
      dispatch({ type: ServiceStrings.redux.limitsUpdate, payload: limits });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
