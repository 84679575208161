import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeMe } from 'react-sizeme';
import classes from './CheckboxFilterAccordion.module.scss';

//This component accepts either props for a boolean filter or children which it will wrap around and provide collapse functionality
class CheckboxFilterAccordion extends Component {
  state = {
    show: true,
    optionsDivHeight: null,
  }

  updateFilterValue = (queryName, value) => {
    const { currentlySelected, optHandler } = this.props;
    optHandler({[queryName]: currentlySelected === value ? null : value});
  };

  render() {
    return (
      <div className={``}>
        <div className={`mb-2`}>
          <button onClick={ev => {
            ev.preventDefault();
            this.setState(prevState => {
              return {
                ...prevState,
                show: !prevState.show,
              }
            });
          }} className={`btn d-flex justify-content-between align-items-center w-100 text-left px-3 h5 mb-0 ${this.state.show ? 'text-light' : 'text-muted'}`}>
            {this.props.filterTitle}
            <FontAwesomeIcon icon={this.state.show ? faChevronCircleUp : faChevronCircleDown}/>
          </button>
        </div>
        <SizeMe monitorHeight>
          {({ size }) => {
            if (!this.state.optionsDivHeight && size.height) {
              this.setState({
                optionsDivHeight: size.height + 'px',
              });
            }
            return <div style={{ maxHeight: this.state.optionsDivHeight }}
              className={`d-flex flex-column pl-3 overflow-hidden ${classes.collapseContainer} ${this.state.show ? '' : classes.collapsed}`}>
              {this.props.options.map(({ label, callbackValue }, index) => {
                const checked = callbackValue === this.props.currentlySelected; 
                return <FormGroup key={label} check inline>
                  <Label className={`text-capitalize text-light`} check>
                    <Input
                      onChange={() => this.updateFilterValue(this.props.filterStateName, callbackValue)}
                      type="checkbox"
                      className={`mr-2`}
                      checked={checked}
                      name={this.props.filterStateName} />
                    <span className={`checkmark ${checked ? "bg-primary" : "bg-checkbox-filter"}`}></span>
                    {label}
                  </Label>
                </FormGroup>
              })}
            </div>
          }}
        </SizeMe>
      </div>
    );
  }
}

export default CheckboxFilterAccordion;