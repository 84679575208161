import { call, put, takeEvery, select } from 'redux-saga/effects';
import { ServiceStrings } from '../../ServiceStrings';
import { submitHardwareQuery } from '../../submitHardwareQuery';

function* fetchResults(action) {
    const state = yield select();
    try {
        const response = yield call(submitHardwareQuery, {...state.queries, ...action.payload });
        yield put({ type: ServiceStrings.redux.resultsUpdate, payload: response.data });
    } catch (err) {
        console.log(err);
    }
}

export function* querySaga() {
    yield takeEvery(ServiceStrings.redux.queryUpdate, fetchResults);
}
