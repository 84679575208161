import { ServiceStrings } from "../../ServiceStrings";

const initialState = [];

export const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ServiceStrings.redux.resultsUpdate:
            return [...action.payload];
        default:
            return [...state];
    }
};