import React, { Component } from 'react';
import { SizeMe } from 'react-sizeme';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Range } from 'rc-slider';
import classes from './SliderFilterAccordion.module.scss';
import { connect } from 'react-redux';

//This component accepts either props for a boolean filter or children which it will wrap around and provide collapse functionality
class SliderFilterAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      optionsDivHeight: null,
      currMin: this.props.min,
      currMax: this.props.max,
    };
  }

  render() {
    const { filterTitle, rangeName, min, max, onAfterChange, curValues } = this.props;
    const minToShow = this.state.currMin ?? curValues[0];
    const maxToShow = this.state.currMax ?? curValues[1];

    return (
      <div className={``}>
        <div className={`mb-2`}>
          <button onClick={ev => {
            ev.preventDefault();
            this.setState(prevState => {
              return {
                ...prevState,
                show: !prevState.show,
              }
            });
          }} className={`btn text-capitalize d-flex justify-content-between align-items-center w-100 text-left px-3 h5 mb-0 ${this.state.show ? 'text-light' : 'text-muted'}`}>
            {filterTitle}
            <FontAwesomeIcon icon={this.state.show ? faChevronCircleUp : faChevronCircleDown}/>
          </button>
        </div>
        <SizeMe monitorHeight>
          {({ size }) => {
            if (!this.state.optionsDivHeight && size.height) {
              this.setState({
                optionsDivHeight: size.height + 'px',
              })
            }
            return <div style={{ maxHeight: this.state.optionsDivHeight }}
              className={`d-flex flex-column px-3 overflow-hidden ${classes.collapseContainer} ${this.state.show ? '' : classes.collapsed}`}>
              <p className={`text-white`}>{minToShow}-{maxToShow}</p>
              <Range 
                name={rangeName}
                min={min}
                max={max}
                onAfterChange={() => {
                  if (this.state.currMin === null || this.state.currMin === undefined) {
                    return;
                  }
                  onAfterChange(this.state.currMin, this.state.currMax);
                  this.setState({
                    currMin: null,
                    currMax: null,
                  });
                }}
                onChange={(values) => {
                  this.setState({
                    currMin: values[0],
                    currMax: values[1]
                  });
                }}
                allowCross={false}
                value={[minToShow, maxToShow]}
              />
            </div>
          }}
        </SizeMe>
      </div>
    );
  }
}

export default SliderFilterAccordion;