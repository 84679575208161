import React, { Component } from "react";
import classes from "./SearchFieldBubbles.module.scss";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { translate } from "../../translate";
import { ServiceStrings } from "../../ServiceStrings";

class SearchFieldBubble extends Component {
  render() {
    const activeQueriesAsArray = [];
    const { queries, updateQuery, limits } = this.props;
    const {
      memoryMin,
      memoryMax,
      cudaCoresMin,
      cudaCoresMax,
      memoryBandwidthMin,
      memoryBandwidthMax,
      memoryInterfaceMin,
      memoryInterfaceMax,
      maximumActiveDisplaysMin,
      maximumActiveDisplaysMax,
      maxPowerConsumptionMin,
      maxPowerConsumptionMax,
      ...remaining
    } = queries;

    const arr = [
      ["memory", "memoryMin", "memoryMax"],
      ["cudaCores", "cudaCoresMin", "cudaCoresMax"],
      ["memoryBandwidth", "memoryBandwidthMin", "memoryBandwidthMax"],
      ["memoryInterface", "memoryInterfaceMin", "memoryInterfaceMax"],
      ["maximumActiveDisplays", "maximumActiveDisplaysMin", "maximumActiveDisplaysMax"],
      ["displayConnectors", "displayConnectorsMin", "displayConnectorsMax"],
      ["maxPowerConsumption", "maxPowerConsumptionMin", "maxPowerConsumptionMax"],
      ["numberOfSlots", "numberOfSlotsMin", "numberOfSlotsMax"],
    ];

    for (let i = 0; i < arr.length; i++) {
      const translation = arr[i][0];
      const minKey = arr[i][1];
      const maxKey = arr[i][2];
      if (queries[minKey] !== limits[minKey] || queries[maxKey] !== limits[maxKey]) {
        activeQueriesAsArray.push({
          translation,
          key: [ minKey, maxKey ],
          value: [ queries[minKey], queries[maxKey] ]
        });
      }
    }

    for (const query in remaining) {
      if (queries[query] !== null && queries[query] !== limits[query]) {
        if (query === "gpuArchitectures" && !queries[query].length) {
          continue;
        }
        activeQueriesAsArray.push({
          translation: query,
          key: query,
          value: queries[query]
        });
      }
    }

    return (
      <ul
        className={`list-unstyled d-flex align-items-center flex-wrap flex-grow mb-0 pl-${
          activeQueriesAsArray.length ? "1" : "3"
        } ${classes.searchBubblesContainer}`}
      >
        {activeQueriesAsArray.map(({ translation, key, value }, index) => {
          if (translation === "gpuArchitectures") {
            return value.map((arch) => {
              return (
                <li className={`my-1`} key={arch}>
                  <div
                    className={`${classes.searchBubble} bg-black rounded-pill w-fit-content d-flex align-items-center mr-1`}
                    style={{ left: "5px" }}
                  >
                    <span className={`text-light pl-3`}>{arch}</span>
                    <button
                      className={`btn ml-2 px-2 py-1 rounded-0 border-left`}
                      onClick={(e) => {
                        updateQuery({
                          gpuArchitectures: value.filter((n) => n != arch)
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className={`text-primary`}
                      />
                    </button>
                  </div>
                </li>
              );
            });
          }
          let translated = translate("yes");
          if (value === false) {
            translated = translate("no");
          } else if (value !== true) {
            translated = value;
          }
          const liIsForMinMax = Array.isArray(value);
          let bubbleValue;
          if (liIsForMinMax) {
            if (value[0] === value[1]) {
              bubbleValue = value[0];
            } else {
              bubbleValue = value.join(" - ");
            }
          } else {
            bubbleValue = translated;
          }
          return (
            <li className={`my-1`} key={translation}>
              <div
                className={`${classes.searchBubble} bg-black rounded-pill w-fit-content d-flex align-items-center mr-1`}
                style={{ left: "5px" }}
              >
                <span className={`text-light pl-3`}>
                  {translate(translation)}: {bubbleValue}
                </span>
                <button
                  className={`btn ml-2 px-2 py-1 rounded-0 border-left`}
                  onClick={(e) => {
                    if (liIsForMinMax) {
                      updateQuery({
                        [key[0]]: limits[key[0]],
                        [key[1]]: limits[key[1]]
                      });
                    } else {
                      const example = { [key]: limits[key] ?? null };
                      updateQuery({ [key]: limits[key] ?? null });
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className={`text-primary`}
                  />
                </button>
              </div>
            </li>
          );
        })}
        {activeQueriesAsArray.find((q) => q.translation === "textQuery") ? null : (
          <li className={`my-1 flex-grow ${classes.textQueryContainer}`}>
            <input
              className={`w-100 h-100 border-0 ${classes.textSearchInput}`}
              type="text"
              placeholder={translate("lookupByKeywords")}
              value={this.props.textQueryValue}
              onChange={(event) => {
                this.props.updateTextQuery(event.target.value);
              }}
              onKeyDown={(ev) => {
                if (
                  ev.key === "Backspace" &&
                  ev.target.value === "" &&
                  activeQueriesAsArray.length > 0
                ) {
                  const queryName =
                    activeQueriesAsArray[activeQueriesAsArray.length - 1].name;
                  updateQuery({
                    [queryName]: limits[queryName] ?? null
                  });
                } else if (
                  ev.key === "Enter" &&
                  ev.target.value.trim() !== ""
                ) {
                  this.props.sendNewQuery();
                }
              }}
            />
          </li>
        )}
      </ul>
    );
  }
}

const mapStateToProps = ({ queries, limits }) => {
  return { queries, limits };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuery: (changedQuery) => {
      dispatch({ type: ServiceStrings.redux.queryUpdate, payload: changedQuery });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFieldBubble);
